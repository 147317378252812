import React from "react"
import ContactSales from "../components/common/ContactSales"
import SEORevamp from "../components/common/SEO_Revamp"
import Testimonials from "../components/common/testimonials/Testimonials"
import Advantages from "../components/dedicated-dev-sections/Advantages"
import Banner from "../components/dedicated-dev-sections/Banner"
import IdealTeam from "../components/dedicated-dev-sections/IdealTeam"
import MidHero from "../components/dedicated-dev-sections/MidHero"
import WhyDDevs from "../components/dedicated-dev-sections/WhyDDevs"
import AngularTechStacks from "../components/application-development/AngularTechStacks"
import AngularTechStacksMobile from "../components/application-development/AngularTechStacksMobile"
import angular from "../images/icons/angular.svg"
import aws from "../images/icons/aws.svg"
import azure from "../images/icons/azure.svg"
import circleci from "../images/icons/circleci.svg"
import codeigniter from "../images/icons/codeigniter.svg"
import cordova from "../images/icons/cordova.svg"
import django from "../images/icons/django.svg"
import elixir from "../images/icons/elixir.svg"
import erlang from "../images/icons/erlang.svg"
import expressjs from "../images/icons/expressjs.svg"
import flutter from "../images/icons/flutter.svg"
import ionic from "../images/icons/ionic.svg"
import ios from "../images/icons/ios.svg"
import laravel from "../images/icons/laravel.svg"
import meteor from "../images/icons/meteor.svg"
import nodejs from "../images/icons/nodejs.svg"
import phoenix from "../images/icons/phoenix.svg"
import php from "../images/icons/php.svg"
import python from "../images/icons/python.svg"
import react from "../images/icons/react.svg"
import redux from "../images/icons/redux.svg"
import swift from "../images/icons/swift.svg"
import vuejs from "../images/icons/vuejs.svg"
import xamarin from "../images/icons/xamarin.svg"
import MainLayout from "../layouts/MainLayout3"

const items = [
  { name: "Amazon AWS", icon: aws },
  { name: "Elixir", icon: elixir },
  { name: "Erlang", icon: erlang },
  { name: "Phoenix-Framework", icon: phoenix },
  { name: "NodeJS", icon: nodejs },
  { name: "React", icon: react },
  { name: "Redux", icon: redux },
  { name: "Angular", icon: angular },
  { name: "VueJS", icon: vuejs },
  { name: "ExpressJS", icon: expressjs },
  { name: "Meteor", icon: meteor },
  { name: "Python", icon: python },
  { name: "Django", icon: django },
  { name: "PHP", icon: php },
  { name: "Laravel", icon: laravel },
  { name: "Codeigniter", icon: codeigniter },
  { name: "Azure", icon: azure },
  { name: "CircleCI", icon: circleci },
  { name: "React Native", icon: react },
  { name: "Ionic", icon: ionic },
  { name: "Flutter", icon: flutter },
  { name: "Xamarin", icon: xamarin },
  { name: "Cordova", icon: cordova },
  { name: "iOS", icon: ios },
  { name: "Swift", icon: swift },
]

const DedicatedDevTeamPage = () => {
  const [isMobile, setIsMobile] = React.useState(false)
  const [isTablet, setIsTablet] = React.useState(false)

  const handleResize = () => {
    if (window.innerWidth <= 768) {
      setIsMobile(true)
      setIsTablet(false)
    } else if (window.innerWidth <= 1280) {
      setIsTablet(true)
      setIsMobile(false)
    } else {
      setIsMobile(false)
      setIsTablet(false)
    }
  }

  React.useEffect(() => {
    setIsMobile(window.innerWidth <= 768)
    setIsTablet(window.innerWidth <= 1280)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return (
    <MainLayout>
      <Banner />
      <WhyDDevs />
      <IdealTeam />
      {!isMobile && !isTablet ? (
        <AngularTechStacks
          heading={"Main Development Technologies We Work With"}
        />
      ) : (
        <AngularTechStacksMobile
          heading={"Main Development Technologies We Work With"}
        />
      )}
      {/* <Technologies
        items={items}
        heading={
          <h2 className="h1">
            <span className="text-primary">Main Development Technologies </span>{" "}
            <br />
            We Work With
          </h2>
        }
      /> */}
      <Testimonials />
      <Advantages />
      <MidHero />
      <ContactSales />
    </MainLayout>
  )
}

export default DedicatedDevTeamPage

export const Head = () => {
  return (
    <SEORevamp
      title="Dedicated Development Team | InvoZone"
      description="Hire a dedicated development team to acquire expert software developers with expertise in multiple technologies to enhance your business operations."
      image="https://invozone-backend.s3.amazonaws.com/dedicated_development_team_2b989ec002.webp"
    />
  )
}
